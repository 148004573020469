import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  Grid,
  InputLabel,
  NativeSelect,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { getParameterByName } from '../../utils/utils';

import getTableData from './actions';
import { Filters } from '../../components';
import tabDefinitions from './tabDefinitions';

import './mainPage.scss';

const MainPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tableData, error } = useSelector(state => state.mainPage);
  const [page, setPage] = useState(1);
  const [goToPage, setGoToPage] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [selectedTab, setSelectedTab] = useState(tabDefinitions.all);
  const [sortValue, setSortValue] = useState('latest');
  const [latestFirst, setLatestFirst] = useState(true);
  const [filtersObject, setFiltersObject] = useState({
    market: '',
    customerId: '',
    timeAndDate: '',
    timeAndDateEnd: '',
    latestFirst: true,
    selectedTab: tabDefinitions.all
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => console.log(`I can see you're not typing. I can use "${goToPage}" now!`), 1000);

    return () => clearTimeout(timeoutId);
  }, [goToPage]);

  useEffect(() => {
    const customerId = getParameterByName('customerId');
    if (customerId) {
      setFiltersObject({
        ...filtersObject,
        customerId
      });
      dispatch(getTableData(itemsPerPage, page, customerId, '', '', '', true, tabDefinitions.all));
    } else {
      dispatch(getTableData(itemsPerPage, page, '', '', '', '', true, tabDefinitions.all));
    }
  }, []);

  useEffect(() => {
    setPage(tableData.paginationInfo.currentPage);
    setItemsPerPage(tableData.paginationInfo.rowsPerPage);
  }, [tableData, error]);

  const handleChangeItemsPerPage = event => {
    setItemsPerPage(event.target.value);
    dispatch(
      getTableData(
        event.target.value,
        goToPage !== '' ? goToPage : page,
        filtersObject.customerId,
        filtersObject.market,
        filtersObject.timeAndDate,
        filtersObject.timeAndDateEnd,
        filtersObject.latestFirst,
        filtersObject.selectedTab
      )
    );
  };

  const handleChangePage = (event, value) => {
    setGoToPage('');
    dispatch(
      getTableData(
        itemsPerPage,
        value,
        filtersObject.customerId,
        filtersObject.market,
        filtersObject.timeAndDate,
        filtersObject.timeAndDateEnd,
        filtersObject.latestFirst,
        filtersObject.selectedTab
      )
    );
  };

  const handleChangeGoToPage = event => {
    setGoToPage(event.target.value);
  };

  const handleTabChange = tab => {
    setSelectedTab(tab);
    setFiltersObject({
      ...filtersObject,
      selectedTab: tab
    });
    dispatch(
      getTableData(
        itemsPerPage,
        page,
        filtersObject.customerId,
        filtersObject.market,
        filtersObject.timeAndDate,
        filtersObject.timeAndDateEnd,
        filtersObject.latestFirst,
        tab
      )
    );
  };

  const handleSortChange = event => {
    if (event.target.value === 'latest') {
      setLatestFirst(true);
      dispatch(
        getTableData(
          itemsPerPage,
          page,
          filtersObject.customerId,
          filtersObject.market,
          filtersObject.timeAndDate,
          filtersObject.timeAndDateEnd,
          true,
          filtersObject.selectedTab
        )
      );
    } else {
      setLatestFirst(false);
      dispatch(
        getTableData(
          itemsPerPage,
          page,
          filtersObject.customerId,
          filtersObject.market,
          filtersObject.timeAndDate,
          filtersObject.timeAndDateEnd,
          false,
          filtersObject.selectedTab
        )
      );
    }
    setSortValue(event.target.value);
  };

  const goToPageClick = () => {
    dispatch(
      getTableData(
        itemsPerPage,
        goToPage,
        filtersObject.customerId,
        filtersObject.market,
        filtersObject.timeAndDate,
        filtersObject.timeAndDateEnd,
        filtersObject.latestFirst,
        filtersObject.selectedTab
      )
    );
  };

  const submitFiltersParent = (market, customerId, timeAndDate, timeAndDateEnd) => {
    setFiltersObject({
      market,
      customerId,
      timeAndDate,
      timeAndDateEnd,
      latestFirst,
      selectedTab
    });
    dispatch(
      getTableData(itemsPerPage, page, customerId, market, timeAndDate, timeAndDateEnd, latestFirst, selectedTab)
    );
  };

  // const goToDetails = submissionId => {
  //   // props.history.push(`details?submissionId=${submissionId}&subscriptionKey=${SUBSCRIPTION_KEY_VERIFICATION}`);
  // };

  return (
    <Paper className="main-page">
      <div>
        <Filters
          submitFiltersParent={(market, customerId, timeAndDate, timeAndDateEnd) =>
            submitFiltersParent(market, customerId, timeAndDate, timeAndDateEnd)
          }
          customerIdFromUrl={filtersObject.customerId}
        />
      </div>
      <div className="tab-container">
        <ul>
          <li
            className={selectedTab === tabDefinitions.all ? 'selected' : ''}
            onClick={() => handleTabChange(tabDefinitions.all)}
          >
            {t('mainPageAll')} ({tableData.paginationInfo.allSubmissionCount})
          </li>
          <li
            className={selectedTab === tabDefinitions.accepted ? 'selected' : ''}
            onClick={() => handleTabChange(tabDefinitions.accepted)}
          >
            {t('mainPageAcceptedTab')} ({tableData.paginationInfo.acceptedCount})
          </li>
          <li
            className={selectedTab === tabDefinitions.rejected ? 'selected' : ''}
            onClick={() => handleTabChange(tabDefinitions.rejected)}
          >
            {t('mainPageRejectedTab')} ({tableData.paginationInfo.rejectedCount})
          </li>
          <li className="no-underline-select">
            <div className="">
              <Select id="market-select" native value={sortValue} onChange={handleSortChange}>
                <option value="latest">{t('mainPageSortLatest')}</option>
                <option value="oldest">{t('mainPageSortOldest')}</option>
              </Select>
            </div>
          </li>
        </ul>
      </div>
      {error !== '' && (
        <Grid container spacing={3} className="error-wrapper">
          <Typography align="center" color="error">
            {error}
          </Typography>
        </Grid>
      )}
      {error === '' && (
        <>
          <TableContainer className="table-container">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {tableData &&
                    tableData.tableHeaders &&
                    tableData.tableHeaders.map(header => (
                      <TableCell key={header.id} className="table-head">
                        {header.label}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData &&
                  tableData.tableRows &&
                  tableData.tableRows.map((row, i) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.customerId + Math.random()}>
                      <TableCell>
                        {page > 1 && tableData.paginationInfo.totalPages > 1
                          ? (page - 1) * itemsPerPage + 1 + i
                          : i + 1}
                      </TableCell>
                      {row.customerId ? <TableCell>{row.customerId}</TableCell> : <TableCell />}
                      {row.market ? <TableCell>{row.market}</TableCell> : <TableCell />}
                      {row.createdAtUtc ? (
                        <TableCell>{`${moment(row.createdAtUtc).utc().format('YYYY-MM-DD HH:mm')} UTC`}</TableCell>
                      ) : (
                        <TableCell />
                      )}
                      {row.result ? (
                        <TableCell>
                          <div
                            className={`status-badge ${
                              row.result.toLowerCase() === 'accepted' ? 'status-badge-success' : 'status-badge-error'
                            }`}
                          >
                            {row.result}
                          </div>
                        </TableCell>
                      ) : (
                        <TableCell />
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="pagination-container">
            <div className="items-per-page-container">
              <FormControl className="items-per-page-form-control">
                <InputLabel shrink htmlFor="items-per-page">
                  {t('mainPageItemsPerPage')}
                </InputLabel>
                <NativeSelect value={itemsPerPage} onChange={handleChangeItemsPerPage}>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </NativeSelect>
              </FormControl>
            </div>
            <Pagination
              className="pagination"
              count={tableData && tableData.paginationInfo ? tableData.paginationInfo.totalPages : 0}
              page={parseInt(page)}
              onChange={handleChangePage}
            />
            <Typography>
              <input
                className="goToPage-input"
                type="number"
                value={goToPage}
                onChange={handleChangeGoToPage}
                min="1"
                max={tableData && tableData.paginationInfo ? tableData.paginationInfo.totalPages : 0}
              />
              <span className="goToPage-go-button" onClick={goToPageClick}>
                {t('mainPageGo')}
              </span>
            </Typography>
          </div>
        </>
      )}
    </Paper>
  );
};

export default withRouter(MainPage);
