import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#669CDB'
    },
    secondary: {
      main: '#fc4503'
    }
  }
});
