import { combineReducers } from 'redux';
import globalReducer from './globalReducer';
import mainPageReducer from '../modules/mainPage/reducer';

const rootReducer = combineReducers({
  globalReducer,
  mainPage: mainPageReducer,
  reports: []
});
export default rootReducer;
