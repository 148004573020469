import axios from './interceptors';

export const GET = (url, headers = {}) =>
  axios.get(`${url}`, {
    headers: {
      ...headers,
      Authorization: ''
    }
  });

export const DELETE = (url, headers = {}, data) =>
  axios.delete(`${url}`, {
    headers: {
      ...headers,
      Authorization: ''
    },
    data
  });

export const POST = (url, headers = {}, data) =>
  axios.post(`${url}`, data, {
    headers: {
      ...headers,
      Authorization: ''
    }
  });

export const PUT = (url, headers = {}, data) =>
  axios.put(`${url}`, data, {
    headers: {
      ...headers,
      Authorization: ''
    }
  });
