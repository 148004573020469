import * as types from './actionNames';
import getTableDataFromAPI from '../services';

const dispatchFetchTableDataSuccess = payload => ({
  type: types.FETCH_MAIN_PAGE_TABLE_DATA,
  payload
});

const dispatchFetchTableDataError = error => ({
  type: types.FETCH_MAIN_PAGE_TABLE_DATA_ERROR,
  error
});

export default (itemsPerPage, page, customerId, market, start, end, latestFirst, submissionType) => async dispatch => {
  try {
    const response = await getTableDataFromAPI(
      itemsPerPage,
      page,
      customerId,
      market,
      start,
      end,
      latestFirst,
      submissionType
    );

    if ((response && response.status === 200) || response.status === 201) {
      dispatch(dispatchFetchTableDataSuccess(response.data));
    } else {
      dispatch(dispatchFetchTableDataError(response.response.data.message || response.response.data[0].message));
    }
  } catch (error) {
    dispatch(dispatchFetchTableDataError('We are sorry but there was an error with your request!'));
  }
};
