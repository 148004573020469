export default {
  en: {
    translation: {
      mainPageAll: 'All',
      mainPageAcceptedTab: 'Accepted',
      mainPageRejectedTab: 'Rejected',
      mainPageSortLatest: 'Latest first',
      mainPageSortOldest: 'Oldest first',
      mainPageItemsPerPage: 'Items per page:',
      mainPageGo: 'Go',
      mainPageFiltersSearch: 'SEARCH',
      mainPageFiltersResetFilters: 'Reset Filters',
      mainPageFiltersTitle: 'Car Sharing Set Up Results',

      detailsPageLabelCustomerId: 'Customer ID',
      detailsPageLabelMarket: 'Market',

      filtersTimeAndDateStart: 'Time & Date (From)',
      filtersTimeAndDateEnd: 'Time & Date (To)',

      generalLabelAll: 'All'
    }
  }
};
