import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import { SUBSCRIPTION_KEY_VERIFICATION } from '../../utils/config';
import { getParameterByName } from '../../utils/utils';
import { Logo, WarningIcon, UserCheckIcon } from '../../assets/images';
import pages from './pageDefinitions';
import './header.scss';

const Header = props => {
  const [active, setActive] = useState(pages.carSharing.key);

  useEffect(() => {
    if (window.location.pathname.indexOf(pages.whitelist.key) !== -1) {
      setActive(pages.whitelist.key);
    } else {
      setActive(pages.carSharing.key);
    }
  }, [window.location.pathname]);

  const goToPage = page => {
    const subscriptionKey = getParameterByName('subscriptionKey');

    if (subscriptionKey && subscriptionKey === SUBSCRIPTION_KEY_VERIFICATION) {
      props.history.push(
        page === pages.carSharing.key
          ? `/?subscriptionKey=${SUBSCRIPTION_KEY_VERIFICATION}`
          : `/whitelist?subscriptionKey=${SUBSCRIPTION_KEY_VERIFICATION}`
      );
    }
  };

  return (
    <Grid container spacing={8} className="grid-header-container">
      <Grid item sm={12} className="header-grid-wrapper">
        <div className="header">
          <div className="menu-container">
            <ul className="menu">
              <li
                className={`menu-item ${active === pages.carSharing.key && 'active'}`}
                onClick={page => goToPage(pages.carSharing.key)}
              >
                <span>
                  <WarningIcon /> {pages.carSharing.label}
                </span>
              </li>
              <li
                className={`menu-item ${active === pages.whitelist.key && 'active'}`}
                onClick={page => goToPage('whitelist')}
              >
                <span>
                  <UserCheckIcon /> {pages.whitelist.label}
                </span>
              </li>
            </ul>
          </div>
          <div className="logo-wrapper">
            <Logo />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default withRouter(Header);
