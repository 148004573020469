import React from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import './loadingScreen.scss';

const LoadingScreen = () => {
  const { loading } = useSelector(state => state.globalReducer);

  if (!loading) return null;

  return (
    <div className="loadingScreen-container">
      <div className="loader">
        <CircularProgress size={100} color="primary" />
      </div>
    </div>
  );
};

export default LoadingScreen;
