import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import store from './store';
import theme from './theme';
import AppRoute from './routes';
import reportWebVitals from './reportWebVitals';
import translationEn from './translations/en';

import './index.scss';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      ...translationEn
    },
    lng: 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false
    }
  });

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <AppRoute />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
