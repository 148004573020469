export const getCategoryBasedOnID = id => {
  const categoryMap = {
    1: 'Car sharing',
    2: 'Damage',
    3: 'Problem',
    4: 'Other',
    5: 'Return',
    6: 'Delivery'
  };

  return categoryMap[id] || '';
};

export const getParameterByName = (name, url = window.location.href) => {
  /*eslint-disable */
  name = name.replace(/[\[\]]/g, '\\$&');
  /* eslint-enable */
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
