import React from 'react';
import { Redirect } from 'react-router-dom';
import { getParameterByName } from '../../utils/utils';
import { SUBSCRIPTION_KEY_VERIFICATION } from '../../utils/config';

const PrivateRoute = props => {
  const { component } = props;

  const Component = component;
  const subscriptionKey = getParameterByName('subscriptionKey');

  const isUserAuthorized = subscriptionKey === SUBSCRIPTION_KEY_VERIFICATION;
  return isUserAuthorized ? <Component {...props} /> : <Redirect to={{ pathname: '/unauthorized' }} />;
};

export default PrivateRoute;
