import React, { useState } from 'react';
import { InputLabel, Grid, Button, Checkbox, TextField, TextareaAutosize, Hidden } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { FileUploadNoSubmit } from '..';

import './addReport.scss';

const GreenCheckbox = withStyles({
  root: {
    color: '#02aa9e',
    '&$checked': {
      color: '#02aa9e'
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

const AddReport = ({ details, setShowAddReport, submissionId, uploadSuccess, addNewReport, category }) => {
  const { t } = useTranslation();
  const [location, setLocation] = useState('');
  const [cause, setCause] = useState('');
  const [description, setDescription] = useState('');
  const [recurringChecked, setRecurringChecked] = useState(false);
  const [currentFile, setCurrentFile] = useState('');
  const [timeAndDateOccuredAt, settimeAndDateOccuredAtUnformatted] = useState('');
  const [errors, setErrors] = useState({
    location: false,
    cause: false,
    timeAndDateOccuredAt: false,
    attachment: false,
    description: false
  });

  const onDateTimeChange = event => {
    settimeAndDateOccuredAtUnformatted(event.target.value);
  };

  const onSaveClick = async () => {
    await validateFields(category !== 1 ? 'validateAll' : 'validationForCarSharing');
    const errorsToCheck = errors;

    const checkFields = fields => {
      let valid = true;
      for (const property in fields) {
        if (fields[property] !== false) {
          valid = false;
        }
      }
      return valid;
    };

    delete errorsToCheck.errors;
    const isFormValid = checkFields(errorsToCheck);

    if (isFormValid) {
      addNewReport(
        location,
        cause,
        category === 1 ? false : recurringChecked,
        category === 1 ? '' : moment.parseZone(timeAndDateOccuredAt).utc().format(),
        description,
        currentFile
      );
    }
  };

  // called every time a file's `status` changes
  const handleChangeStatusFile = ({ meta, file }, status) => {
    if (file) {
      setErrors({
        ...errors,
        attachment: false
      });
    }
    if (status === 'removed') {
      setCurrentFile('');
    } else if (status === 'done') {
      setCurrentFile(file);
    }
  };

  const validateFields = fieldName => {
    const renderErrMsg = field => `${field} ${t('genericErrorMandatoryText')}`;

    switch (fieldName) {
      case 'location':
        errors[fieldName] = !location ? renderErrMsg(t('genericLocation')) : false;
        break;

      case 'cause':
        errors[fieldName] = !cause ? renderErrMsg(t('genericCause')) : false;
        break;

      case 'timeAndDateOccuredAt':
        errors[fieldName] = !timeAndDateOccuredAt ? renderErrMsg(t('genericOccuredAt')) : false;
        break;

      case 'description':
        errors[fieldName] = !description ? renderErrMsg(t('genericDescription')) : false;
        break;

      case 'validateAll':
        errors.location = !location ? renderErrMsg(t('genericLocation')) : false;
        errors.cause = !cause ? renderErrMsg(t('genericCause')) : false;
        errors.timeAndDateOccuredAt = !timeAndDateOccuredAt ? renderErrMsg(t('genericOccuredAt')) : false;
        errors.attachment = !currentFile ? renderErrMsg(t('genericAttachment')) : false;
        errors.description = !description ? renderErrMsg(t('genericDescription')) : false;
        break;
      case 'validationForCarSharing':
        errors.attachment = !currentFile ? renderErrMsg(t('genericAttachment')) : false;
        break;

      default:
        break;
    }

    setErrors({
      ...errors,
      errors
    });
  };

  const renderFieldError = fieldName =>
    errors[fieldName] ? <span className="error-msg">{errors[fieldName]}</span> : <span />;

  return (
    <Grid
      container
      spacing={8}
      className={`grid-conainter reports-grid ${
        details.reports && details.reports.length === 0 && 'light-gray'
      } add-report-container`}
    >
      <span className="close-add-report" onClick={() => setShowAddReport(false)}>
        x
      </span>
      <Grid container justify="center" alignItems="center" className="grid-container error-container">
        {category !== 1 && renderFieldError('location')}
        {category !== 1 && renderFieldError('cause')}
        {renderFieldError('attachment')}
        {category !== 1 && renderFieldError('description')}
        {category !== 1 && renderFieldError('timeAndDateOccuredAt')}
      </Grid>
      <Grid item lg={2} md={3} sm={6} xs={6}>
        <div className={`add-report-generic ${category !== 1 && errors.location && 'error'}`}>
          <InputLabel className="label" htmlFor="location">
            {t('detailsPageLabelLocation')}
          </InputLabel>
          <input
            id="location"
            type="text"
            value={location}
            onBlur={() => category !== 1 && validateFields('location')}
            onChange={event => setLocation(event.target.value)}
            className="input-text"
          />
        </div>
      </Grid>
      <Grid item lg={2} md={3} sm={6} xs={6}>
        <div className={`add-report-generic ${category !== 1 && errors.cause && 'error'}`}>
          <InputLabel className="label" htmlFor="cause">
            {t('detailsPageLabelCause')}
          </InputLabel>
          <input
            id="cause"
            type="text"
            value={cause}
            onBlur={() => category !== 1 && validateFields('cause')}
            onChange={event => setCause(event.target.value)}
            className="input-text"
          />
        </div>
      </Grid>
      <Grid item md={1} sm={6} xs={6}>
        <div className="add-report-generic">
          <InputLabel className="label" htmlFor="recurring">
            {t('detailsPageLabelRecurring')}
          </InputLabel>
          {category !== 1 && (
            <GreenCheckbox
              checked={recurringChecked}
              onChange={event => setRecurringChecked(event.target.checked)}
              name="checkedG"
            />
          )}
        </div>
      </Grid>
      <Grid item lg={2} md={3} sm={6} xs={6}>
        <div className={`add-report-generic ${category !== 1 && errors.timeAndDateOccuredAt && 'error'}`}>
          <InputLabel className="label" htmlFor="datetime-local">
            {t('addReportOccuredAt')}
          </InputLabel>
          {category !== 1 && (
            <TextField
              className="text-field"
              id="datetime-local"
              type="datetime-local"
              onBlur={() => category !== 1 && validateFields('timeAndDateOccuredAt')}
              value={timeAndDateOccuredAt}
              onChange={onDateTimeChange}
            />
          )}
        </div>
      </Grid>
      <Hidden only={['sm', 'md', 'xs']}>
        <Grid item lg={5} md={10} sm={6} xs={6}>
          <Button className="save-report-button" variant="contained" onClick={onSaveClick}>
            {t('addReportSave')}
          </Button>
        </Grid>
      </Hidden>

      <Grid item sm={9} xs={12}>
        <div className={`add-report-generic ${category !== 1 && errors.description && 'error'}`}>
          <InputLabel className="label" htmlFor="description">
            {t('detailsPageLabelDescription')}
          </InputLabel>
          <TextareaAutosize
            id="description"
            rowsMax={6}
            className="textarea"
            aria-label="maximum height"
            // placeholder="Maximum 4 rows"
            value={description}
            onBlur={() => category !== 1 && validateFields('description')}
            onChange={event => setDescription(event.target.value)}
            // defaultValue=""
          />
        </div>
      </Grid>
      <Grid container spacing={3} className={`second-level-grid-conainter ${errors.attachment && 'error-attachment'}`}>
        <span className="attachment-label">{t('detailsPageLabelAttachments')}</span>
        <Grid item lg={2} md={3} sm={6} xs={12} className="image-grid-wrapper">
          <FileUploadNoSubmit
            submissionId={submissionId}
            uploadSuccess={uploadSuccess}
            onBlur={() => validateFields('attachment')}
            handleChangeStatusFile={handleChangeStatusFile}
          />
        </Grid>
      </Grid>
      <Hidden only={['lg', 'xl']}>
        <Grid item lg={3} md={8} sm={6} xs={6}>
          <Button className="save-report-button-md-down" variant="contained" onClick={onSaveClick}>
            {t('addReportSave')}
          </Button>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default AddReport;
