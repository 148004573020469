export default {
  carSharing: {
    key: 'car-sharing',
    label: 'Car Sharing Set Up'
  },
  whitelist: {
    key: 'whitelist',
    label: 'Whitelist'
  }
};
