import React, { useState, useEffect } from 'react';
import { InputLabel, Select, Grid, TextField, Button } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import './filters.scss';

const Filters = ({ submitFiltersParent, customerIdFromUrl }) => {
  const { t } = useTranslation();
  const [market, setMarket] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [timeAndDate, setTimeAndDate] = useState('');
  const [timeAndDateUnformatted, setTimeAndDateUnformatted] = useState('');
  const [timeAndDateEndUnformatted, setTimeAndDateEndUnformatted] = useState('');
  const [timeAndDateEnd, setTimeAndDateEnd] = useState('');

  useEffect(() => {
    if (customerIdFromUrl !== customerId) {
      setCustomerId(customerIdFromUrl);
    }
  }, [customerIdFromUrl]);

  const handleChangeMarket = event => {
    setMarket(event.target.value);
  };

  const onDateTimeChange = event => {
    setTimeAndDateUnformatted(event.target.value);
    setTimeAndDate(moment.parseZone(event.target.value).utc().format());
  };

  const onDateTimeChangeEnd = event => {
    setTimeAndDateEndUnformatted(event.target.value);
    setTimeAndDateEnd(moment.parseZone(event.target.value).utc().format());
  };

  const changeCustomerId = event => {
    setCustomerId(event.target.value);
  };

  const submitFilters = () => {
    submitFiltersParent(market, customerId, timeAndDate, timeAndDateEnd);
  };

  const resetFilters = e => {
    e.stopPropagation();
    setTimeAndDateUnformatted('');
    setTimeAndDateEndUnformatted('');
    setTimeAndDate('');
    setTimeAndDateEnd('');
    setMarket('');
    setCustomerId('');

    submitFiltersParent('', '', '', '', '', '', '', '', '', '', '');
  };

  return (
    <div className="filters-container">
      <h2 className="filters-title">{t('mainPageFiltersTitle')}</h2>

      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12} lg={6}>
          <Grid container spacing={3} justify="flex-start">
            <Grid item>
              <div className="generic-filter customer-id">
                <InputLabel className="label" htmlFor="customer-id">
                  {t('detailsPageLabelCustomerId')}
                </InputLabel>
                <input
                  id="customer-id"
                  className="input-text"
                  type="text"
                  value={customerId}
                  onChange={changeCustomerId}
                />
              </div>
            </Grid>
            <Grid item>
              <div className="generic-filter market-select">
                <InputLabel className="label" htmlFor="market-select">
                  {t('detailsPageLabelMarket')}
                </InputLabel>
                <Select id="market-select" native value={market} onChange={handleChangeMarket}>
                  <option value="">{t('generalLabelAll')}</option>
                  <option value="SE">SE</option>
                  <option value="NL">NL</option>
                  <option value="BE">BE</option>
                  <option value="DE">DE</option>
                  <option value="IT">IT</option>
                  <option value="ES">ES</option>
                  <option value="FR">FR</option>
                </Select>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Grid container spacing={3} className="filters-container-right-pane">
            <Grid item>
              <div className="generic-filter time-and-date">
                <InputLabel className="label" htmlFor="datetime-local">
                  {t('filtersTimeAndDateStart')}
                </InputLabel>
                <TextField
                  id="datetime-local"
                  type="datetime-local"
                  value={timeAndDateUnformatted}
                  onChange={onDateTimeChange}
                />
              </div>
            </Grid>
            <Grid item>
              <div className="generic-filter time-and-date">
                <InputLabel className="label" htmlFor="datetime-local">
                  {t('filtersTimeAndDateEnd')}
                </InputLabel>
                <TextField
                  id="datetime-local"
                  type="datetime-local"
                  value={timeAndDateEndUnformatted}
                  onChange={onDateTimeChangeEnd}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div className="submit-area">
        <span className="reset-button" onClick={e => resetFilters(e)}>
          <RefreshIcon className="reset-icon" />
          {t('mainPageFiltersResetFilters')}
        </span>
        <Button className="submit-button" variant="contained" color="primary" onClick={submitFilters}>
          <SearchIcon />
          {t('mainPageFiltersSearch')}
        </Button>
      </div>
    </div>
  );
};

export default Filters;
