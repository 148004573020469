import axios from 'axios';
import store from '../store';

import { SUBSCRIPTION_KEY_VERIFICATION } from './config';

const axiosInstance = axios.create();
const { dispatch } = store;

const setLoadingScreen = loading => ({
  type: 'GLOBAL_REDUCER::SET_LOADING_SCREEN',
  payload: loading
});

axiosInstance.interceptors.request.use(
  payload => {
    const config = { ...payload };
    config.headers.subscriptionKey = SUBSCRIPTION_KEY_VERIFICATION;

    dispatch(setLoadingScreen(true));
    return config;
  },
  error => {
    dispatch(setLoadingScreen(false));
    return error;
  }
);

axiosInstance.interceptors.response.use(
  response => {
    dispatch(setLoadingScreen(false));
    return response;
  },
  error => {
    dispatch(setLoadingScreen(false));
    return error;
  }
);

export default axiosInstance;
