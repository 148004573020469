import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import MainPage from '../modules/mainPage';
import { PrivateRoute, NoSubscriptionKey, NotFoundPage, LoadingScreen, Header } from '../components';

const AppRoute = () => (
  <BrowserRouter>
    <Header />
    <Switch>
      <Route path="/unauthorized" component={NoSubscriptionKey} />

      <PrivateRoute exact path="/" component={MainPage} />
      <PrivateRoute component={NotFoundPage} />
    </Switch>
    <LoadingScreen />
  </BrowserRouter>
);

export default AppRoute;
