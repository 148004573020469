import React from 'react';

import { Logo } from '../../assets/images';

import './noSubscriptionKey.scss';

const NoSubscriptionKey = props => (
  <div className="container">
    <div className="header">
      <Logo />
    </div>
    <h1>
      Unauthorized <span>:(</span>
    </h1>
    <p>We are sorry, but you are not authorized to view this page.</p>

    <i>401</i>
  </div>
);

export default NoSubscriptionKey;
