const initialState = {
  loading: false
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GLOBAL_REDUCER::SET_LOADING_SCREEN': {
      return {
        ...state,
        loading: action.payload
      };
    }
    default:
      return state;
  }
};

export default globalReducer;
